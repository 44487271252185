import Date from '@components/views/Events/atoms/Date';
import Label from '@components/views/Events/atoms/Label';
import { EventProps } from '@components/views/Events/types';
import clsx from 'clsx';
import { EventTileSimpleProps } from '../../EventTileSimple';

type EventHeaderProps = Pick<EventProps, 'date' | 'products'>;

const EventHeader = (
  props: EventHeaderProps & {
    isColumn?: boolean;
    isHighlighted?: boolean;
    isDisabled: EventTileSimpleProps['isDisabled'];
  },
) => {
  const { date, isColumn, isHighlighted, isDisabled, products } = props;

  const isDateHighlighted = isColumn && isHighlighted;
  return (
    <div
      className={clsx('flex justify-between', {
        'md:flex-col justify-between space-y-4 bg-neutral-0 py-30 pr-16 md:pr-0':
          isColumn,
        'items-start': !isColumn,
        'h-full': isHighlighted,
        grayscale: isDisabled,
      })}
    >
      <div className="min-h-[56px] flex items-center">
        <Date
          date={date}
          isHighlighted={isDateHighlighted}
          isListView={isColumn}
        />
      </div>
      {Array.isArray(products) && (
        <div className="flex flex-wrap gap-8 justify-end">
          {products.map((product) => {
            return (
              <Label
                key={`product-label-${product.meta?.abbreviation}`}
                colors={product.meta?.colors}
                abbreviation={product.meta?.abbreviation}
                isDisabled={isDisabled}
                productName={product.post_title}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default EventHeader;
