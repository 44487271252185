import Image from 'next/image';

const LockedIcon = () => {
  return (
    <Image
      src={'/images/lock.svg'}
      width={11.66}
      height={16.67}
      alt="ikona kłódki"
    />
  );
};

export default LockedIcon;
