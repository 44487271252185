import 'dayjs/locale/pl';
import dayjs from 'dayjs';

export const displayDateWeekday = (date: string) =>
  dayjs(date).locale('pl').format('dddd');

export const displayMonthName = (date: string) => {
  const monthIndex = dayjs(date).month();
  switch (monthIndex) {
    case 0:
      return 'stycznia';
    case 1:
      return 'lutego';
    case 2:
      return 'marca';
    case 3:
      return 'kwietnia';
    case 4:
      return 'maja';
    case 5:
      return 'czerwca';
    case 6:
      return 'lipca';
    case 7:
      return 'sierpnia';
    case 8:
      return 'września';
    case 9:
      return 'października';
    case 10:
      return 'listopada';
    case 11:
      return 'grudnia';
    default:
      return dayjs(date).locale('pl').format('MMMM');
  }
};

export const displayTwoDigitsDay = (date: string) =>
  dayjs(date).locale('pl').format('DD');
