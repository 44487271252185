import { EventProps } from '@components/views/Events/types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';

dayjs.extend(customParseFormat);
dayjs.extend(duration);

const calculateEventTotalHourDuration = (
  fromTime: EventProps['start_time'],
  toTime: EventProps['end_time'],
  isWholeDayDuration?: EventProps['event_all_day'],
): string | undefined => {
  if (!fromTime || !toTime || isWholeDayDuration === '1') return;

  const fromTimeDate = dayjs(fromTime, 'HH:mm:ss');
  const toTimeDate = dayjs(toTime, 'HH:mm:ss');
  const hourDifferenceInMinutes = dayjs(toTimeDate).diff(
    fromTimeDate,
    'minutes',
  );

  if (!hourDifferenceInMinutes) return;

  const totalHours = hourDifferenceInMinutes;
  const hourDifferenceString = dayjs
    .duration(totalHours, 'minutes')
    .format('H:mm');

  const [, minutes] = hourDifferenceString.split(':');

  //display 'H:mm' format if duration has some minutes
  if (minutes !== '00') {
    return hourDifferenceString;
  }

  return dayjs.duration(totalHours, 'minutes').asHours().toString();
};

export default calculateEventTotalHourDuration;
