import { EventProps } from '@components/views/Events/types';
import { EventTileSimpleProps } from '../../EventTileSimple';
import Chip from '@components/views/Chip';
import clsx from 'clsx';
import LockedIcon from '../../atoms/LockedIcon';
import EventAdditionalInfo from '../EventAdditionalInfo';
import { ViewTheme } from 'src/context/viewTheme.context';

type EventFooterProps = Pick<
  EventProps,
  | 'start_time'
  | 'end_time'
  | 'post_status'
  | 'location'
  | 'additional_info'
  | 'event_days'
  | 'event_duration'
  | 'meta'
> &
  Pick<EventTileSimpleProps, 'isDisabled' | 'isLocked'> & {
    isExtended?: boolean;
    theme?: ViewTheme;
  };

const EventFooter = (props: EventFooterProps) => {
  const { isLocked, isDisabled, isExtended, theme } = props;

  return (
    <div className="flex justify-between">
      {!isDisabled ? (
        <div
          className={clsx('flex flex-wrap w-full items-center lg:gap-4', {
            'justify-between': !isExtended,
          })}
        >
          <EventAdditionalInfo {...props} variant={theme} />

          {isLocked && (
            <div className="ml-auto">
              <LockedIcon />
            </div>
          )}
        </div>
      ) : (
        <Chip variant="BORDERED-DISABLED" fullWidth>
          Wydarzenie archiwalne
        </Chip>
      )}
    </div>
  );
};

export default EventFooter;
