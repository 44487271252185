import { EventProps } from '@components/views/Events/types';
import dayjs from 'dayjs';

const getDaysDifference = (
  olderDate: EventProps['start_date'],
  youngerDate: EventProps['end_date'],
) => {
  const difference = dayjs(youngerDate).diff(olderDate, 'days');

  if (difference >= 1) return difference + 1;

  return difference;
};

export default getDaysDifference;
