import { isDateOlderThanToday } from 'src/utils/isDateOlderThanToday';
import { displayEventLocation } from './displayEventLocation';

export const convertEventDataToTileProps = (event: any) => {
  const meta = event?.meta;

  if (!meta) return event;

  return {
    ...event,
    date: meta.start_date,
    title: meta.name || '',
    start_time: meta.start_time,
    end_time: meta.end_time,
    excerpt: meta.post_excerpt,
    slug: meta.slug,
    post_status: meta.post_status,
    label: event.meta_data?.product?.meta && event.meta_data?.product.meta,
    isLocked: event.meta_data?.is_locked,
    categories: meta.categories?.terms,
    taxonomies: event.taxonomies,
    event_days: event.meta_data?.event_days,
    event_duration: event.meta_data?.event_duration,
    isHighlighted: false,
    isDisabled: isDateOlderThanToday(meta.start_date),
    location: displayEventLocation(meta),
    additional_info: event.meta_data?.additional_info,
    ...(event?.url &&
      !event?.link && {
        link: event.url,
      }),
  };
};
