import { displayDateWeekday } from 'src/utils/displayDateWeekday';
import { formatDateToObj } from 'src/utils/formatDateToObj';
import { EventDateType } from '@components/views/Events/types';
import clsx from 'clsx';

const Date = ({
  date,
  isHighlighted,
  isListView,
}: {
  date: EventDateType;
  isHighlighted?: boolean;
  isListView?: boolean;
}) => {
  const weekDay = displayDateWeekday(date);
  const { years: year, months: month, date: day } = formatDateToObj(date);
  const formatted_month = String(month + 1).padStart(2, '0');

  return (
    <div
      className={clsx('grid gap-1 px-10 xxl:px-0', {
        'bg-neutral-1 pt-15 pb-12 h-full': isListView && !isHighlighted,
      })}
    >
      <div className={clsx('flex gap-2 justify-center text-center')}>
        {day && (
          <div className="text-2xl leading-none">
            {String(day).padStart(2, '0')}
          </div>
        )}
        <div className="flex flex-col text-xs text-neutral-4 leading-none ">
          {formatted_month && <span>{formatted_month}</span>}
          {year && <span> {year.toString().slice(2, 4)}</span>}
        </div>
      </div>

      {weekDay && (
        <p className="text-xs text-neutral-4 text-center capitalize">
          {weekDay}
        </p>
      )}
    </div>
  );
};

export default Date;
