import { EventTaxonomy } from '@components/views/Events/types';

export const formatCategoriesToArray = (
  taxonomies: EventTaxonomy[],
): { name: string }[] =>
  taxonomies && taxonomies.length > 0
    ? taxonomies.map((taxonomy) => ({
        name: taxonomy.terms?.name,
      }))
    : [];
