import EventBody from '@components/views/Events/molecules/EventBody';
import EventFooter from '@components/views/Events/molecules/EventFooter';
import EventHeader from '@components/views/Events/molecules/EventHeader';
import Link from 'next/link';
import clsx from 'clsx';
import { EventProps } from '@components/views/Events//types';
import { ViewTheme } from 'src/context/viewTheme.context';
import { HTMLAttributes } from 'react';
import { formatCategoriesToArray } from '@components/containers/Events/utils/formatCategoriesToArray';

export interface EventTileSimpleProps {
  theme?: ViewTheme;
  isHighlighted?: boolean;
  isDisabled?: boolean;
  isLocked?: boolean;
  variant?: 'HOVER' | 'GRAYSCALE';
  noBorder?: boolean;
}

const EventTileSimple = (
  props: EventProps & EventTileSimpleProps & HTMLAttributes<HTMLAnchorElement>,
) => {
  const {
    additional_info,
    date,
    taxonomies,
    title,
    post_status,
    start_time,
    end_time,
    event_days,
    event_duration,
    location,
    excerpt,
    meta,
    theme,
    isHighlighted,
    isDisabled,
    isLocked,
    variant,
    link,
    onMouseEnter,
    onMouseLeave,
    noBorder,
    meta_data,
  } = props;

  const { recurrence_event_title } = meta;

  const { products } = meta_data;

  const eventTypesCollection =
    taxonomies && formatCategoriesToArray(taxonomies?.['event_type']);

  const isExtended = theme === 'LIST';

  return (
    <Link href={link} passHref>
      <a
        className={clsx(
          'flex h-full transition-all outer-tile hover:shadow-[48px_48px_104px_-24px_rgba(19,26,32,0.08)]',
          {
            'p-16 md:p-32 flex-col md:min-h-[34.4rem]': !isExtended,
            'flex-col md:flex-row': isExtended,
            'py-16 md:py-32 bg-neutral-0 rounded-4': isHighlighted,
            'border-transparent ': !isDisabled,
            'bg-transparent border border-neutral-2': isDisabled,
            '!bg-transparent border border-neutral-2 opacity-40 transition-all duration-300 cursor-pointer':
              variant === 'GRAYSCALE',
            'opacity-100 border-transparent shadow-elevation3 transition-all duration-300 cursor-pointer':
              variant === 'HOVER',
            'bg-neutral-0':
              (variant !== 'GRAYSCALE' && !isDisabled) ||
              (!isExtended && !isDisabled),
            border: !noBorder,
            'border-0': noBorder,
          },
        )}
        {...{ onMouseEnter, onMouseLeave }}
      >
        <div
          className={clsx({
            'mb-20': !isExtended,
            '-ml-8': isHighlighted,
            'opacity-60': isDisabled,
          })}
        >
          <EventHeader
            {...{
              date,
              isColumn: isExtended,
              isHighlighted,
              isDisabled,
              products,
            }}
          />
        </div>
        <div
          className={clsx(
            'flex flex-col space-y-12 h-full justify-between flex-1',
            {
              'px-12 md:px-30 py-14': isExtended,
            },
          )}
        >
          <div>
            <EventBody
              {...{
                eventTypes: eventTypesCollection,
                title,
                excerpt,
                isHighlighted,
                isDisabled,
                isCycle: !!recurrence_event_title,
              }}
            />
          </div>
          <EventFooter
            {...{
              post_status,
              start_time,
              end_time,
              location,
              event_duration,
              event_days,
              isDisabled,
              isExtended,
              isLocked,
              additional_info,
              ...meta,
              theme,
            }}
          />
        </div>
      </a>
    </Link>
  );
};

export default EventTileSimple;
