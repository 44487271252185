import { formatHour } from '@components/containers/Events/utils/formatHour';

export const displayTime = (hours: any) => {
  const { start_time, end_time } = hours;

  if (start_time && !end_time) {
    return `${formatHour(start_time)}`;
  } else if (end_time && !start_time) {
    return `${formatHour(end_time)}`;
  } else if (start_time && end_time) {
    return [formatHour(start_time), formatHour(end_time)].join('-');
  }
  return '';
};
