import { EventCategoryType } from '@components/views/Events/types';
import clsx from 'clsx';
import Image from 'next/image';

const Category = (props: EventCategoryType) => {
  const { name, isDisabled, hasIconRemoved } = props;
  return (
    <div className="flex items-center">
      <div className="flex items-center space-x-8">
        <div
          className={clsx('w-4 h-8 flex-shrink-0 rounded-4', {
            'bg-neutral-5': isDisabled,
            'bg-neutral-9': !isDisabled,
          })}
        ></div>
        <p
          className={clsx('uppercase text-xxs font-medium', {
            'text-neutral-5': isDisabled,
          })}
          dangerouslySetInnerHTML={{ __html: name }}
        />
      </div>
      {!hasIconRemoved && (
        <div className="flex items-center">
          <span
            className={clsx(
              'hidden md:block ml-4 mr-14 w-2 h-2 rounded-full flex-shrink-0',
              {
                'bg-neutral-4': !isDisabled,
                'bg-neutral-6': isDisabled,
              },
            )}
          />
          <div className="flex-shrink-0 items-center flex">
            <Image
              src={'/images/dash.svg'}
              width={16}
              height={16}
              alt="ikona wyrózniająca"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Category;
