import SingleEventSidebarElement from '@components/containers/SingleEvent/SingleEventSidebar/SingleEventSidebarElement';
import { ViewTheme } from 'src/context/viewTheme.context';
import { displayEventLocation } from '@components/containers/Events/utils/displayEventLocation';
import { displayPrice } from 'src/utils/displayPrice';
import { displayTime } from 'src/utils/displayTime';
import getDaysDifference from 'src/utils/getDaysDifference';
import { useMemo } from 'react';
import calculateEventTotalHourDuration from 'src/utils/calculateEventTotalHourDuration';

const EventAdditionalInfo = (props: any) => {
  const {
    start_time,
    end_time,
    additional_info,
    variant,
    isDisabled,
    recurrence_event_title,
    start_date,
    end_date,
    event_all_day: isWholeDayDuration,
    is_locked,
    event_days,
  } = props;

  const eventLocation = displayEventLocation(props);

  const isGrid = variant === ('GRID' as ViewTheme);

  const sharedProps = {
    hasTitle: !!variant,
    hasIcon: !isGrid,
    isDisabled,
  };

  const daysDifference = useMemo(
    () => getDaysDifference(start_date, end_date),
    [end_date, start_date],
  );

  const totalTimeDuration = calculateEventTotalHourDuration(
    start_time,
    end_time,
    isWholeDayDuration,
  );

  return (
    <>
      <SingleEventSidebarElement
        label={
          daysDifference > 0
            ? `${event_days.length} dni`
            : displayTime({ start_time, end_time })
        }
        additionalLabel={event_days.length && `(${totalTimeDuration} h)`}
        title={'Czas trwania szkolenia'}
        icon={'/images/events/clock.svg'}
        {...sharedProps}
      />

      {!isGrid &&
        (additional_info?.price_group?.is_free ||
          additional_info?.price_group?.content) && (
          <SingleEventSidebarElement
            label={`${displayPrice(
              additional_info.price_group.is_free,
              additional_info.price_group.content,
            )}`}
            additionalLabel={
              !additional_info.price_group.is_free &&
              is_locked &&
              additional_info.price_group.content
                ? '(bezpłatne dla członków archiclub)'
                : ''
            }
            title={'Koszt'}
            icon={'/images/events/price.svg'}
            {...sharedProps}
          />
        )}

      {eventLocation && (
        <SingleEventSidebarElement
          label={eventLocation}
          title={'Lokalizacja'}
          icon={'/images/events/location.svg'}
          {...sharedProps}
        />
      )}

      {!isGrid && additional_info?.organizer_group?.content && (
        <SingleEventSidebarElement
          label={additional_info.organizer_group.content}
          title={'Organizator'}
          icon={'/images/events/organizer.svg'}
          {...sharedProps}
        />
      )}

      {!isGrid && recurrence_event_title && (
        <SingleEventSidebarElement
          label={recurrence_event_title}
          title={''}
          icon={'/images/events/layer.svg'}
          {...sharedProps}
        />
      )}
    </>
  );
};

export default EventAdditionalInfo;
