import clsx from 'clsx';
import Image from 'next/image';
interface SingleEventSidebarElementProps {
  title?: string;
  label: string;
  additionalLabel?: string;
  icon?: string;
  hasTitle?: boolean;
  hasIcon?: boolean;
  isDisabled?: boolean;
}

const SingleEventSidebarElement = (props: SingleEventSidebarElementProps) => {
  const { title, icon, label, additionalLabel, hasTitle, hasIcon, isDisabled } =
    props;

  return (
    <div className="flex items-center">
      {icon && hasIcon && (
        <div
          className={clsx(
            'bg-neutral-0 p-8 md:p-18 flex items-center justify-center mr-16 flex-shrink-0',
          )}
        >
          <div
            className={clsx('flex-shrink-0 flex items-center justify-center', {
              'opacity-50': isDisabled,
            })}
          >
            <Image src={icon} width={22} height={22} alt="ikona" />
          </div>
        </div>
      )}
      <div className="grid gap-4">
        {title && !hasTitle && (
          <p
            className="text-sm text-neutral-5"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        )}
        <p
          className={clsx('text-sm md:text-sm', {
            'text-neutral-5': isDisabled,
          })}
        >
          {label}{' '}
          {additionalLabel && (
            <span className="text-neutral-4">{additionalLabel}</span>
          )}
        </p>
      </div>
    </div>
  );
};

export default SingleEventSidebarElement;
