import { EventProps } from '@components/views/Events/types';
import { theme } from '@styles/theme';
import Tooltip from '@components/views/Tooltip';

const Label = (
  props: EventProps['label'] & { isDisabled?: boolean; productName?: string },
) => {
  const { colors, abbreviation, isDisabled, productName } = props;
  if (!abbreviation) return null;

  return (
    <Tooltip
      tooltipContent={productName}
      tooltipProps={{ disabled: !productName }}
    >
      <div
        style={{
          backgroundColor: isDisabled
            ? theme.colors.neutral[2]
            : colors?.washed || theme.colors.neutral[0],
          color: isDisabled
            ? theme.colors.neutral[5]
            : colors?.main || theme.colors.neutral[9],
        }}
        className={'w-56 h-56 flex items-center justify-center'}
      >
        <span className="uppercase text-lg font-extrabold">{abbreviation}</span>
      </div>
    </Tooltip>
  );
};

export default Label;
