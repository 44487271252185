import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';
import BeakIcon from '../../../assets/images/icons/beak.svg';
import Tippy, { TippyProps } from '@tippyjs/react';

export interface TooltipProps {
  tooltipProps?: TippyProps;
  text?: string;
  tooltipContent: ReactNode | string;
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  children,
  tooltipProps,
  tooltipContent,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Tippy
      className="bg-neutral-0 text-xs font-medium rounded-8 border border-neutral-9 px-10 py-12"
      visible={visible}
      render={(attrs) => {
        return (
          <div
            tabIndex={-1}
            {...attrs}
            className={clsx(
              'bg-neutral-0 text-xs font-medium rounded-8 border border-neutral-9 px-10 py-12 relative transform transition-[opacity,transform]',
              { 'opacity-0 translate-y-4 pointer-events-none': !visible },
            )}
          >
            {tooltipContent}
            <div data-popper-arrow="" className="-bottom-[0.9rem]">
              <BeakIcon />
            </div>
          </div>
        );
      }}
      {...tooltipProps}
    >
      <div
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        {children}
      </div>
    </Tippy>
  );
};
export default Tooltip;
