import Category from '@components/views/Events/atoms/Category';
import { EventCategoryType, EventProps } from '@components/views/Events/types';

type CategoryCollectionProps = Pick<EventProps, 'categories' | 'isDisabled'> & {
  hasIconRemoved?: EventCategoryType['hasIconRemoved'];
};

const CategoryCollection = (props: CategoryCollectionProps) => {
  const { categories, isDisabled, hasIconRemoved } = props;
  const hasCollectionItems = categories && categories.length > 0;
  if (!hasCollectionItems) return null;

  return (
    <div className="flex gap-12">
      {categories.map((category) => (
        <Category
          {...category}
          key={category.name}
          isDisabled={isDisabled}
          hasIconRemoved={hasIconRemoved}
        />
      ))}
    </div>
  );
};

export default CategoryCollection;
