import { EventProps } from '@components/views/Events/types';
import CategoryCollection from '@components/views/Events/molecules/CategoryCollection';
import { EventTileSimpleProps } from '../../EventTileSimple';
import clsx from 'clsx';

type EventBodyType = Pick<EventProps, 'categories' | 'title' | 'excerpt'>;

interface EventBodyProps extends EventBodyType {
  isHighlighted?: boolean;
  isDisabled?: EventTileSimpleProps['isDisabled'];
  isCycle?: boolean;
  eventTypes?: EventProps['categories'];
}

const EventBody = (props: EventBodyProps) => {
  const { title, excerpt, isHighlighted, isDisabled, isCycle, eventTypes } =
    props;
  const hasExcerpt = excerpt && isHighlighted;

  return (
    <div className="grid gap-8">
      <div className={clsx({ 'opacity-60': isDisabled })}>
        <CategoryCollection categories={eventTypes} hasIconRemoved={!isCycle} />
      </div>

      <p
        className={clsx('font-extrabold text-lg', {
          'text-neutral-4': isDisabled,
        })}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {hasExcerpt && (
        <p
          className={clsx('text-neutral-5', {
            'text-neutral-3': isDisabled,
          })}
          dangerouslySetInnerHTML={{ __html: excerpt }}
        ></p>
      )}
    </div>
  );
};

export default EventBody;
